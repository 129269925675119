import { render, staticRenderFns } from "./crudSurrender.vue?vue&type=template&id=ea73c7bc&scoped=true&"
import script from "./crudSurrender.vue?vue&type=script&lang=js&"
export * from "./crudSurrender.vue?vue&type=script&lang=js&"
import style0 from "./crudSurrender.vue?vue&type=style&index=0&lang=css&"
import style1 from "./crudSurrender.vue?vue&type=style&index=1&id=ea73c7bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea73c7bc",
  null
  
)

export default component.exports